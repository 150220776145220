export const JavaScriptLogo = (props: React.ComponentPropsWithoutRef<'svg'>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" {...props}>
      <g clipPath="url(#a)">
        <path fill="#FFE93E" d="M0 4a4 4 0 0 1 4-4h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4Z" />
        <path
          fill="#13131A"
          // eslint-disable-next-line max-len
          d="M22.035 18.276c-.175-1.095-.89-2.014-3.004-2.872-.735-.338-1.554-.58-1.798-1.136-.087-.324-.098-.507-.043-.703.157-.636.917-.835 1.52-.652.387.13.754.429.976.905 1.035-.67 1.033-.666 1.756-1.127-.264-.41-.406-.6-.58-.775-.622-.696-1.47-1.054-2.828-1.027-.235.03-.472.062-.707.091-.678.171-1.324.527-1.702 1.004-1.136 1.29-.812 3.545.57 4.474 1.362 1.022 3.363 1.255 3.618 2.21.249 1.171-.86 1.55-1.962 1.415-.812-.169-1.264-.582-1.752-1.332-.899.52-.899.52-1.822 1.051.219.48.449.696.816 1.111 1.739 1.764 6.09 1.677 6.87-.992.031-.091.241-.703.072-1.645Zm-8.988-7.245h-2.245c0 1.939-.01 3.864-.01 5.803 0 1.235.065 2.366-.136 2.713-.329.682-1.18.598-1.567.465-.395-.194-.596-.47-.829-.86-.063-.111-.111-.198-.127-.205-.61.372-1.216.746-1.825 1.118.303.623.75 1.163 1.323 1.515.855.513 2.005.67 3.207.394.783-.228 1.458-.7 1.812-1.419.51-.942.401-2.083.397-3.344.011-2.058 0-4.116 0-6.18Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
