import React from 'react';
/* eslint-disable */
export function MobileIcon(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M9.99935 15.0003H10.006M5.83268 1.66699H14.166C15.0865 1.66699 15.8327 2.41318 15.8327 3.33366V16.667C15.8327 17.5875 15.0865 18.3337 14.166 18.3337H5.83268C4.91221 18.3337 4.16602 17.5875 4.16602 16.667V3.33366C4.16602 2.41318 4.91221 1.66699 5.83268 1.66699Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
