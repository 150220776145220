export const JsGradient = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_263_2800)">
        <path d="M0 0H24V24H0V0Z" fill="url(#paint0_linear_263_2800)" />
        <path
          // eslint-disable-next-line max-len
          d="M22.0357 18.276C21.8601 17.1809 21.146 16.2615 19.0312 15.4037C18.2966 15.0661 17.4776 14.8243 17.2335 14.2676C17.1468 13.9436 17.1354 13.7611 17.1901 13.5649C17.3475 12.9284 18.1072 12.73 18.7095 12.9125C19.0973 13.0425 19.4646 13.3414 19.6859 13.8182C20.7217 13.1475 20.7194 13.152 21.4426 12.6912C21.1779 12.2805 21.0365 12.0912 20.8631 11.9155C20.2403 11.2197 19.3916 10.8615 18.0342 10.8889C17.7992 10.9186 17.562 10.9505 17.327 10.9802C16.6494 11.1513 16.0038 11.5072 15.6251 11.984C14.489 13.2729 14.8129 15.5292 16.1954 16.4577C17.5574 17.4798 19.5582 17.7125 19.8137 18.6684C20.0624 19.8387 18.9536 20.2174 17.8517 20.0828C17.0395 19.914 16.5879 19.5011 16.0996 18.7505C15.2008 19.2707 15.2008 19.2707 14.2768 19.8022C14.4958 20.2813 14.7262 20.498 15.0936 20.9132C16.832 22.6767 21.1825 22.5901 21.9628 19.9209C21.9947 19.8296 22.2046 19.2182 22.0357 18.276ZM13.0471 11.0303H10.8023C10.8023 12.9695 10.7932 14.895 10.7932 16.8341C10.7932 18.0684 10.8571 19.1999 10.6563 19.5467C10.3278 20.2288 9.47683 20.1444 9.08899 20.0121C8.69432 19.8181 8.49356 19.5421 8.26086 19.152C8.19698 19.0402 8.14907 18.9535 8.13308 18.9467C7.52398 19.3185 6.91711 19.6927 6.30798 20.0646C6.61143 20.6874 7.05855 21.2281 7.63118 21.5794C8.48669 22.0927 9.6365 22.2501 10.8388 21.974C11.6213 21.7459 12.2966 21.2737 12.6502 20.555C13.1612 19.6128 13.0517 18.4722 13.0471 17.2106C13.0586 15.1528 13.0471 13.095 13.0471 11.0303Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_263_2800" x1="0" y1="12" x2="24" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#DD2476" />
          <stop offset="1" stopColor="#FF512F" />
        </linearGradient>
        <clipPath id="clip0_263_2800">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
