export const DisconnectGradient = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        // eslint-disable-next-line max-len
        d="M6.375 3C5.75273 3 5.25 3.50273 5.25 4.125V7.5H7.5V4.125C7.5 3.50273 6.99727 3 6.375 3ZM13.125 3C12.5027 3 12 3.50273 12 4.125V7.5H14.25V4.125C14.25 3.50273 13.7473 3 13.125 3ZM4.125 8.625C3.50273 8.625 3 9.12773 3 9.75C3 10.3723 3.50273 10.875 4.125 10.875V12C4.125 14.7211 6.05859 16.9922 8.625 17.5125V19.875C8.625 20.4973 9.12773 21 9.75 21C10.3723 21 10.875 20.4973 10.875 19.875V17.5125C11.3074 17.4246 11.7223 17.2875 12.109 17.1082C12.0352 16.7285 12 16.3383 12 15.9375C12 13.1145 13.8914 10.7344 16.4754 9.98906C16.493 9.91172 16.5 9.83086 16.5 9.75C16.5 9.12773 15.9973 8.625 15.375 8.625H4.125ZM18.1875 21C19.5302 21 20.8178 20.4666 21.7672 19.5172C22.7166 18.5678 23.25 17.2802 23.25 15.9375C23.25 14.5948 22.7166 13.3072 21.7672 12.3578C20.8178 11.4084 19.5302 10.875 18.1875 10.875C16.8448 10.875 15.5572 11.4084 14.6078 12.3578C13.6584 13.3072 13.125 14.5948 13.125 15.9375C13.125 17.2802 13.6584 18.5678 14.6078 19.5172C15.5572 20.4666 16.8448 21 18.1875 21ZM20.2723 14.6473L18.982 15.9375L20.2723 17.2277C20.4902 17.4457 20.4902 17.8043 20.2723 18.0223C20.0543 18.2402 19.6957 18.2402 19.4777 18.0223L18.1875 16.732L16.8973 18.0223C16.6793 18.2402 16.3207 18.2402 16.1027 18.0223C15.8848 17.8043 15.8848 17.4457 16.1027 17.2277L17.393 15.9375L16.1027 14.6473C15.8848 14.4293 15.8848 14.0707 16.1027 13.8527C16.3207 13.6348 16.6793 13.6348 16.8973 13.8527L18.1875 15.143L19.4777 13.8527C19.6957 13.6348 20.0543 13.6348 20.2723 13.8527C20.4902 14.0707 20.4902 14.4293 20.2723 14.6473Z"
        fill="#E64545"
      />
    </svg>
  );
};
