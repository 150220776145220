/* eslint-disable max-len */
import React from 'react';

export function DelayAction(props: React.ComponentPropsWithoutRef<'svg'>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      data-blue-gradient-svg
      {...props}
    >
      <mask
        id="mask0_433_2481"
        style={{ maskType: 'alpha' }}
        width="24"
        height="24"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path fill="#525266" d="M0 0H24V24H0z"></path>
      </mask>
      <g mask="url(#mask0_433_2481)">
        <path
          fill="url(#paint0_linear_433_2481)"
          d="M15.25 5c-.35 0-.646-.12-.887-.362A1.207 1.207 0 0114 3.75c0-.35.12-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.12.646-.363.888A1.207 1.207 0 0115.25 5zm0 16.5c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0114 20.25c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 01-.887.363zm4-13c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0118 7.25c0-.35.12-.646.363-.888.241-.241.537-.362.887-.362s.646.12.887.362c.242.242.363.538.363.888s-.12.646-.363.887a1.207 1.207 0 01-.887.363zm0 9.5c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0118 16.75c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 01-.887.363zm1.5-4.75c-.35 0-.646-.12-.887-.363A1.207 1.207 0 0119.5 12c0-.35.12-.646.363-.887.241-.242.537-.363.887-.363s.646.12.887.363c.242.241.363.537.363.887s-.12.646-.363.887a1.207 1.207 0 01-.887.363zM12 22a9.738 9.738 0 01-3.9-.788 10.099 10.099 0 01-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 012 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 012.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0112 2v2c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20v2zm0-8c-.55 0-1.02-.196-1.412-.588A1.926 1.926 0 0110 12c0-.083.004-.17.012-.262a.929.929 0 01.063-.263L8 9.4 9.4 8l2.075 2.075c.067-.017.242-.042.525-.075.55 0 1.02.196 1.412.588.392.391.588.862.588 1.412 0 .55-.196 1.02-.588 1.412A1.926 1.926 0 0112 14z"
        ></path>
      </g>
      <defs>
        <linearGradient id="paint0_linear_433_2481" x1="2" x2="22" y1="12" y2="12" gradientUnits="userSpaceOnUse">
          <stop stopColor="#525266"></stop>
          <stop offset="1" stopColor="#525266"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
